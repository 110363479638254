<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>Institution</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard </router-link>\ Institutions
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn
                                        v-if="checkIsAccessible('institution', 'create')"
                                        @click="__create()"
                                        class="mt-1 btn btn-primary"
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add Institutions
                                    </v-btn>
                                    <v-btn
                                        v-if="checkIsAccessible('institution-type', 'create')"
                                        @click="schoolsTypes()"
                                        class="mt-1 ml-3 btn btn-standard"
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-university</v-icon>&nbsp;
                                        Institution Types
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field
                                            label="Name"
                                            v-model="search.title"
                                            v-on:keyup.enter="__search"
                                            @input="search.title = $event !== null ? $event : ''"
                                            clearable
                                            outlined
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                            :items="institution_types"
                                            v-model="search.institution_type_id"
                                            v-on:keyup.enter="__search"
                                            @input="search.institution_type_id = $event !== null ? $event : ''"
                                            label="Institution Type"
                                            item-text="title"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                    ></v-select>
                                </v-col>
                              <v-col cols="12" sm="6" md="2">
                                    <v-select
                                            :items="countries"
                                            v-model="search.country_id"
                                            v-on:keyup.enter="__search"
                                            @input="search.country_id = $event !== null ? $event : ''"
                                            label="Country"
                                            item-text="title"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                    ></v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="2">
                                    <v-select
                                            label="Status"
                                            v-model="search.is_active"
                                            v-on:keyup.enter="__search"
                                            @input="search.is_active = $event !== null ? $event : ''"
                                            item-text="name"
                                            item-value="value"
                                            :items="status"
                                            clearable
                                            outlined
                                            dense
                                    ></v-select>
                                </v-col>
                              <v-col cols="12" sm="6" md="2" class="text-right">
                                    <v-btn
                                      @click.prevent="__search"
                                      class="mt-1 btn btn-primary"
                                      :loading="loading"
                                    >
                                        <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div class="table-responsive">
                            <v-skeleton-loader
                                    v-if="loading"
                                    type="table-thead"
                            >
                            </v-skeleton-loader>

                            <v-skeleton-loader
                                    v-if="loading"
                                    type="table-row-divider@25"
                            >
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                <tr class="px-3">
                                    <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')"><strong>Name</strong></th>
                                    <th><strong>Country</strong></th>
                                    <th><strong>Institution Type</strong></th>
<!--                                    <th><strong>ABN && ACN</strong></th>-->
                                    <th><strong>Is Featured</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th class="pr-3 text-center"><strong>Action</strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template>
                                    <tr v-for="institute in sortedItems" :key="institute.id" >
                                        <td>
                                            <a @click="__edit(institute.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                {{ institute.title }}
                                            </a>
                                        </td>
                                        <td class="text-left">
                                            {{ institute.country ? institute.country : '--' }}
                                        </td>
                                        <td>
                                            {{ institute.institution_type }}
                                        </td>
<!--                                      <td>-->
<!--                                        <b>ABN:</b> <span>{{ institute.anb ? institute.anb  : 'N/A' }}</span> <br>-->
<!--                                        <b>ACN:</b> <span>{{ institute.acn ? institute.acn  : 'N/A' }}</span>-->
<!--                                      </td>-->
                                        <td>
                                          <span class="badge badge-success text-lg`"
                                                v-bind:class="{ 'badge-success': institute.is_featured, 'badge-danger': !institute.is_featured }"
                                          >{{ institute.is_featured ? 'Yes' : 'No' }}</span>
                                        </td>
                                        <td>
                                          <span class="badge badge-success text-lg`"
                                                v-bind:class="{ 'badge-success': institute.is_active, 'badge-danger': !institute.is_active }"
                                          >{{ institute.is_active ? 'Active' : 'Inactive' }}</span>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <template>
                                                <b-dropdown
                                                        size="sm"
                                                        variant="link"
                                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                        no-caret
                                                        right
                                                        no-flip
                                                >
                                                    <template v-slot:button-content>
                                                        <i class="ki ki-bold-more-hor"></i>
                                                    </template>
                                                    <!--begin::Navigation-->
                                                    <div class="navi navi-hover min-w-md-250px">
                                                        <b-dropdown-text tag="div" class="navi-item"    v-if="checkIsAccessible('institution', 'edit')">
                                                            <a @click="__edit(institute.id)" class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                                            </span>
                                                                <span class="navi-text">Edit</span>
                                                            </a>
                                                        </b-dropdown-text>
                                                        <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('institution', 'delete')">
                                                            <a class="navi-link" @click.prevent="__delete(institute.id)">
                                                                <span class="navi-icon">
                                                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                                                </span>
                                                                <span class="navi-text">Delete</span>
                                                            </a>
                                                        </b-dropdown-text>
                                                    </div>
                                                </b-dropdown>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr v-if="sortedItems.length == 0" >
                                        <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                            <b-pagination
                                    v-if="sortedItems.length > 0"
                                    class="pull-right mt-7"
                                    @input="__getAll"
                                    :disabled="loading"
                                    v-model="page"
                                    :total-rows="total"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>

    import InstitutionService from "@/services/academic/institution/InstitutionService";
    import InstitutionTypesService from "@/services/academic/institution/type/InstitutionTypesService";
    import CountryService from "@/services/country/CountryService";
    const country = new CountryService();
    const institutionTypesService = new InstitutionTypesService()
    const institutionService = new InstitutionService();

    export default {
        name: "University-Index",
        data() {
            return{
                loading: false,
                menu: false,
                institutions:[],
                institution_types:[],
                categories:[],
                total: null,
                perPage: null,
                page: null,
                sort: {
                    key: '',
                    isAsc: false
                },
                status: [
                    { name: 'Active', value: '1'},
                    { name: 'Inactive', value: '0'},
                ],
                search:{
                    title:'',
                    institution_type_id: '',
                    publish_date: '',
                    is_active:'',
                }
            }
        },
        mounted() {
            this.__getAll();
            this.getInstitutionTypes();
            this.getCountries();
        },
        computed: {
            sortedItems() {
                const list = this.institutions.slice();
                if (!!this.sort.key) {
                    list.sort((a, b) => {
                        a = a[this.sort.key]
                        b = b[this.sort.key]
                        return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
                    });
                }
                return list;
            }
        },
        methods: {
          getCountries() {
            country
                .getAllCountry({is_operating_country: 1})
                .then((response) => {
                  this.countries = response.data.data;
                })
                .catch(() => {
                })
          },
          __create(){
                this.$router.push({
                    name:"institutions-create"
                });
            },
          getInstitutionTypes() {
            this.isLoading = true;
            institutionTypesService
                .all()
                .then(response => {
                  this.institution_types = response.data.data;
                  this.isLoading = false;
                })
                .catch((err) => {
                }).finally(() => {
              this.isLoading = false;
            });
          },// update
            __edit(instituteId){
                this.$tabs.open({
                    name:'institutions-edit',
                    params:{ institution_id: instituteId}
                });
            },
          // delete
            __delete(id) {
                this.$confirm({
                    message: `Are you sure? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                        if (confirm) {
                            institutionService
                                .delete(id)
                                .then((response) => {
                                    this.__getAll()
                                    this.$snotify.success("Institution Deleted Successfully ");
                                })
                                .catch((err) => {
                                    this.$snotify.error("Oops something went wrong");
                                });
                        }
                    },
                });
            },

            // fetch
            __getAll(){
                this.loading = true;
                institutionService
                    .paginate(this.search,this.page)
                    .then(response => {
                        this.institutions = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$snotify.error("Oops something went wrong");
                    });
            },

            // search
            __search(){
                this.__getAll();
            },
          // CRUD METHODS
            sortedClass(key) {
                return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
            },
            sortBy(key) {
                this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
                this.sort.key = key;
            },

            // router and redirection
            schoolsTypes(){
                this.$router.push({
                    name: "institution-type",
                });
            },




        }
    }
</script>
<style lang="sass">
    table
        th.sorted
            &.asc::after
                display: inline-block
                content: '▼'
                color: red

            &.desc::after
                display: inline-block
                content: '▲'
                color: red

</style>>
